import { useRef, useState, useEffect } from "react";
import {
  BsFacebook,
  BsFillCheckCircleFill,
  BsInstagram,
  BsLinkedin,
  BsSpotify,
  BsWhatsapp,
} from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { Card } from "./Card";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { HiOutlineMail, HiPhone } from "react-icons/hi";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { FaCheckCircle } from "react-icons/fa";

export default function App() {
  const [slides, setSlides] = useState([
    <Card
      img={"./images/icons/archivio.png"}
      title={"GESTIONE ARCHIVI"}
      text={
        "Conserva in modo sicuro e organizzato tutti i documenti relativi alla sicurezza sul lavoro. Sfrutta a pieno i vantaggi della notarizzazione blockchain."
      }
    />,
    <Card
      img={"./images/icons/generazione.png"}
      title={"GENERAZIONE DOCUMENTI"}
      text={
        "Genera con semplicità e rapidità i documenti essenziali come ad esempio nomine, programmi, organigrammi e monitoraggio degli adempimenti."
      }
    />,
    <Card
      img={"./images/icons/aule.png"}
      title={"AULE FORMAZIONE"}
      text={
        "Crea e gestisci con semplicità le aule per la formazione con registrazione dei corsisti e monitoraggio dei progressi dello stato della formazione."
      }
    />,
    <Card
      img={"./images/icons/attestati.png"}
      title={"ATTESTATI PIÙ RAPIDI"}
      text={
        "Ottieni gli attestati di partecipazione in modo semplice. Gestisci con semplicità la pre-fatturazione dei corsi di formazione."
      }
    />,
    <Card
      img={"./images/icons/monitoraggio.png"}
      title={"MONITORAGGIO SEMPLIFICATO"}
      text={
        "Monitora la compliance dei diversi adempimenti aziendali, grazie a report e dashboard intuitive e semplici."
      }
    />,
    <Card
      img={"./images/icons/dvr.png"}
      title={"GESTIONE DVR"}
      text={
        "Diminuisci i tempi dedicati alla creazione dei DVR (Documento di Valutazione dei Rischi) grazie al sistema di generazione guidata."
      }
    />,
    <Card
      img={"./images/icons/pianificazione.png"}
      title={"PIANIFICAZIONE E ORGANIZZAZIONE"}
      text={
        "Pianifica precisamente le visite mediche tramite un comodo scadenzario. Migliora la produttività generando in maniera automatica protocolli sanitari e nomine."
      }
    />,
    <Card
      img={"./images/icons/soprallughi.png"}
      title={"GESTIONE SOPRALLUOGHI"}
      text={
        "Organizza i sopralluoghi nelle sedi operative e crea verbali di sicurezza dettagliati. Gestisci facilmente la pre-fatturazione delle visite e dei sopralluoghi."
      }
    />,
  ]);
  const buttonMail = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [price, setPrice] = useState(1);
  const [tipo, setTipo] = useState(null);
  const offerte = [
    { label: "Starter", id: 0 },
    { label: "Professional", id: 1 },
    { label: "Enterprise", id: 2 },
    { label: "Koala VR", id: 3 },
  ];

  const tipologia = [
    { label: "Tipologia utente...", id: 0 },
    { label: "Datore di lavoro", id: 1 },
    { label: "Rspp interno", id: 2 },
    { label: "Libero professionista", id: 3 },
    { label: "Società di consulenza", id: 4 },
    { label: "Altro", id: 5 },
  ];
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState({
    nome: "",
    cognome: "",
    azienda: "",
    telefono: "",
    offerta: "Professional",
    email: "",
    messagio: "",
    tipologia: "",
    accessi: null,
  });

  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  var xDown = null;
  var yDown = null;

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    //The Math.abs() static method returns the absolute value of a number
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        handleNextSlide();
      } else {
        handlePrevSlide();
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }

  const handlePrevSlide = () => {
    const isFirstSlide = currentSlideIndex === 0;
    const newIndex = isFirstSlide ? 7 : currentSlideIndex - 1;
    setCurrentSlideIndex(newIndex);
  };

  const handleNextSlide = () => {
    const isLastSlide = currentSlideIndex === 7;
    const newIndex = isLastSlide ? 0 : currentSlideIndex + 1;
    setCurrentSlideIndex(newIndex);
  };

  const handleDotClick = (slideIndex) => {
    setCurrentSlideIndex(slideIndex);
  };

  const handleChange = (value) => {
    setVerified(true);
  };

  const handleInputs = (e) => {
    let u = { ...user };
    u[e.target.name] = e.target.value;
    setUser(u);
    console.log(u);
  };

  const sendEmail = () => {
    if (
      user.email !== "" &&
      user.email.includes("@") &&
      user.email.includes(".")
    ) {
      //.post("http://localhost:5000/api/sendemail", user)
      if (verified) {
        return axios
          .post("https://koala-hse.millergroup.it/api/sendemail", user)
          .then(
            toast.success("Email inviata con successo!", {
              position: "top-right",
              filialiClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }),
            setUser({
              nome: "",
              cognome: "",
              azienda: "",
              telefono: "",
              offerta: "Professional",
              email: "",
              tipologia: "",
              accessi: "",
              messagio: "",
            })
          )
          .catch(function (error) {
            console.log(error);
          });
      } else {
        toast.error("Per favore seleziona il reCAPTCHA!", {
          position: "top-right",
          filialiClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.error("Inserisci un indirizzo email valido!", {
        position: "top-right",
        filialiClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleOfferte = (ind) => {
    setPrice(ind);
    let u = { ...user };
    const off = offerte.filter((el) => el.id === ind)[0];
    console.log(off.label);
    u.offerta = off.label;
    setUser(u);
  };
  const handleTipologie = (ind) => {
    setTipo(ind);
    let u = { ...user };
    const tip = tipologia.filter((el) => el.id === ind)[0];
    u.tipologia = tip.label;
    setUser(u);
  };

  const handleAncor = () => {
    buttonMail.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      buttonMail.current.style.scale = "1.2";
    }, 400);
    setTimeout(() => {
      buttonMail.current.style.scale = "1";
    }, 900);
  };
  return (
    <>
      <ToastContainer />
      <div className=" bg-darkMiller">
        <section className="mx-auto py-5 px-5 text-center w-full bg-white relative z-20">
          <div className="mx-auto md:mx-0 w-52 h-20 bg-contain bg-no-repeat bg-center bg-logo"></div>
        </section>

        <section className="w-full relative z-10 overflow-hidden bg-[#0A6497]">
          <div className="flex items-center relative">
            <div className="mx-0 lg:mx-6 mb-2 text-center md:text-left md:w-1/2 px-5 lg:px-10 py-10 lg:py-20">
              <h2 className="text-[46px] lg:text-[68px] font-bold leading-tight  text-white ">
                Software per la <br className="lg:block hidden" />
                gestione HSE
              </h2>
              <p className="mt-6 lg:mt-10 lg:pr-14 text-[28px] lg:text-[30px] font-semibold leading-tight text-white">
                Koala è una piattaforma unica che integra sicurezza sul lavoro,
                formazione, sorveglianza sanitaria e wellbeing.
              </p>
              <button
                className="py-2.5 px-6 mt-6 lg:mt-10 text-sm lg:text-lg font-semibold tracking-wide text-white hover:text-darkMiller hover:bg-white bg-lightMiller hover:scale-105 transition-all ease-in-out duration-300 rounded-full cursor-pointer"
                onClick={handleAncor}
              >
                RICHIEDI LA DEMO
              </button>
            </div>
          </div>
          <img
            src={require("./images/Software Koala_hero.png")}
            alt=""
            className="hidden md:block absolute z-20 top-[50%] -right-[40%] lg:-right-[35%] xl:-right-[25%] 2xl:-right-[10%] -translate-y-[50%] h-full "
          />
        </section>
        <section className="h-3 w-full bg-white"></section>
        <section>
          <RevealOnScroll>
            <div className="grid grid-cols-2 gap-6 px-5 lg:px-10 w-full pb-20 lg:pt-10 pt-20 ">
              <div className="col-span-2 space-y-4">
                <h2 className=" text-white text-title">Cosa può fare Koala?</h2>
                <h3 className=" text-white text-subtitle text-justify">
                  Koala è il software di Miller Group per la gestione HSE che
                  permette ad aziende e professionisti della sicurezza di
                  migliorare la propria produttività e semplificare i processi
                  interni con il massimo livello di integrazione e tutela.
                </h3>
              </div>
              <div className="col-span-2 lg:col-span-1 w-full">
                <div className="mx-auto max-w-xl text-left flex flex-wrap mt-4">
                  <div className=" w-full hidden lg:grid grid-rows-3 grid-flow-col gap-x-4">
                    <div className="text-white w-20 h-20 row-span-3 self-center bg-suite bg-cover bg-center"></div>

                    <h6 className="text-xl text-white font-semibold w-full text-left col-span-1 lg:col-span-2 self-end">
                      SUITE ALL IN ONE
                    </h6>

                    <p className="mt-2 mb-4 text-slate-300 text-justify row-span-2 col-span-2 leading-tight">
                      Piattaforma unica che integra sicurezza sul lavoro,
                      formazione e sorveglianza sanitaria per semplificare i
                      tuoi processi interni
                    </p>
                  </div>
                  <div className=" w-full flex lg:hidden flex-nowrap mt-4">
                    <div className="w-fit my-auto">
                      <div className="text-white w-20 h-20  row-span-3 self-center bg-suite bg-cover bg-center"></div>
                    </div>
                    <div className="">
                      <h6 className="text-xl text-white w-full font-semibold text-left ">
                        SUITE ALL IN ONE
                      </h6>

                      <p className="mt-2 text-slate-300 text-justify leading-tight">
                        Piattaforma unica che integra sicurezza sul lavoro,
                        formazione e sorveglianza sanitaria per semplificare i
                        tuoi processi interni
                      </p>
                    </div>
                  </div>
                  <div className=" w-full hidden lg:grid grid-rows-3 grid-flow-col gap-x-4">
                    <div className="text-white w-20 h-20 row-span-3 self-center bg-produttivita bg-cover bg-center"></div>
                    <h6 className="text-xl text-white font-semibold w-full text-left col-span-1 lg:col-span-2 self-end">
                      MIGLIORE PRODUTTIVITÀ
                    </h6>
                    <p className="mt-2 mb-4 text-slate-300 text-justify row-span-2 col-span-2 leading-tight">
                      Semplifica la gestione dei processi e agevola il
                      monitoraggio degli adempimenti di legge in materia di
                      salute e sicurezza
                    </p>
                  </div>
                  <div className=" w-full flex lg:hidden flex-nowrap mt-4">
                    <div className="w-fit my-auto">
                      <div className="text-white w-20 h-20 row-span-3 self-center bg-produttivita bg-cover bg-center"></div>
                    </div>
                    <div className="">
                      <h6 className="text-xl text-white w-full font-semibold text-left ">
                        MIGLIORE PRODUTTIVITÀ
                      </h6>

                      <p className="mt-2 text-slate-300 text-justify leading-tight">
                        Semplifica la gestione dei processi e agevola il
                        monitoraggio degli adempimenti di legge in materia di
                        salute e sicurezza
                      </p>
                    </div>
                  </div>
                  <div className=" w-full hidden lg:grid grid-rows-3 grid-flow-col gap-x-4">
                    <div className="text-white w-20 h-20 row-span-3 self-center bg-software bg-cover bg-center"></div>
                    <h6 className="text-xl text-white font-semibold w-full text-left col-span-1 lg:col-span-2 self-end uppercase">
                      Compatibilità software HR
                    </h6>
                    <p className="mt-2 mb-4 text-slate-300 text-justify row-span-2 col-span-2 leading-tight">
                      Automatizza la gestione delle anagrafiche grazie
                      all'integrazione con il gestionale HR aziendale
                    </p>
                  </div>
                  <div className=" w-full flex lg:hidden flex-nowrap mt-4">
                    <div className="w-fit my-auto">
                      <div className="text-white w-20 h-20 row-span-3 self-center bg-software bg-cover bg-center"></div>
                    </div>
                    <div className="">
                      <h6 className="text-xl text-white w-full font-semibold text-left uppercase">
                        Compatibilità software HR
                      </h6>

                      <p className="mt-2 text-slate-300 text-justify leading-tight">
                        Automatizza la gestione delle anagrafiche grazie
                        all'integrazione con il gestionale HR aziendale
                      </p>
                    </div>
                  </div>
                  <div className=" w-full hidden lg:grid grid-rows-3 grid-flow-col gap-x-4">
                    <div className="text-white w-20 h-20 row-span-3 self-center bg-blockchain bg-cover bg-center"></div>
                    <h6 className="text-xl text-white font-semibold w-full text-left col-span-1 lg:col-span-2 self-end uppercase">
                      Tecnologia BLOCKCHAIN
                    </h6>
                    <p className="mt-2 mb-4 text-slate-300 text-justify row-span-2 col-span-2 leading-tight">
                      La piattaforma è nativamente sviluppata per integrare i
                      massimi livelli di notarizzazioni e sicurezza tramite
                      tecnologia blockchain
                    </p>
                  </div>
                  <div className=" w-full flex lg:hidden flex-nowrap mt-4">
                    <div className="w-fit my-auto">
                      <div className="text-white w-20 h-20 row-span-3 self-center bg-blockchain bg-cover bg-center"></div>
                    </div>
                    <div className="">
                      <h6 className="text-xl text-white w-full font-semibold text-left uppercase">
                        Tecnologia BLOCKCHAIN
                      </h6>

                      <p className="mt-2 text-slate-300 text-justify leading-tight">
                        La piattaforma è nativamente sviluppata per integrare i
                        massimi livelli di notarizzazioni e sicurezza tramite
                        tecnologia blockchain
                      </p>
                    </div>
                  </div>
                  <div className=" w-full hidden lg:grid grid-rows-3 grid-flow-col gap-x-4">
                    <div className="text-white w-20 h-20 row-span-3 self-center bg-intelligenza bg-cover bg-center"></div>
                    <h6 className="text-xl text-white font-semibold w-full text-left col-span-1 lg:col-span-2 self-end">
                      INTELLIGENZA ARTIFICIALE
                    </h6>
                    <p className="mt-2 mb-4 text-slate-300 text-justify row-span-2 col-span-2 leading-tight">
                      Sfrutta le potenzialità dell’AI per sviluppare contenuti
                      tecnici tramite chat-bot e per le tue attività quotidiane
                    </p>
                  </div>
                  <div className=" w-full flex lg:hidden flex-nowrap mt-4">
                    <div className="w-fit my-auto">
                      <div className="text-white w-20 h-20 row-span-3 self-center bg-intelligenza bg-cover bg-center"></div>
                    </div>
                    <div className="">
                      <h6 className="text-xl text-white w-full font-semibold text-left ">
                        INTELLIGENZA ARTIFICIALE
                      </h6>

                      <p className="mt-2 text-slate-300 text-justify leading-tight">
                        Sfrutta le potenzialità dell’AI per sviluppare contenuti
                        tecnici tramite chat-bot e per le tue attività
                        quotidiane
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1 flex justify-center items-center w-full p-6 mt-10 lg:mt-0">
                <iframe
                  className="w-full h-[450px] lg:h-full rounded-lg" //absolute -top-64
                  src="https://www.youtube.com/embed/Tu8qvD2PlVQ?si=XxX7GfDLRKhgm2D8"
                ></iframe>
                {/* <img
                  src={require("./images/new-mockup.png")}
                  alt=""
                  className="w-fit h-full md:max-h-[350px] max-h-[290px]"
                /> */}
              </div>
            </div>
          </RevealOnScroll>
        </section>
        <section className="flex flex-col w-full px-5 md:px-10 py-5 md:py-10 bg-cover bg-fixed bg-center justify-center items-center bg-bg2560">
          <RevealOnScroll>
            <div className="w-full mb-4">
              <div className=" flex justify-center md:justify-start mb-5">
                <span className="uppercase md:text-8px text-sm font-bold text-lightMiller border border-lightMiller rounded-md lg:px-8 px-6 py-[7px] leading-tight text-center">
                  I vantaggi di Koala
                </span>
              </div>
              <h2 className=" text-darkMiller text-title">
                Scopri i vantaggi di Koala
              </h2>
            </div>
            <div className="hidden lg:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4 mt-6">
              <Card
                img={"./images/icons/archivio.png"}
                title={"GESTIONE ARCHIVI"}
                text={
                  "Conserva in modo sicuro e organizzato tutti i documenti relativi alla sicurezza sul lavoro. Sfrutta a pieno i vantaggi della notarizzazione blockchain."
                }
              />
              <Card
                img={"./images/icons/generazione.png"}
                title={"GENERAZIONE DOCUMENTI"}
                text={
                  "Genera con semplicità e rapidità i documenti essenziali come ad esempio nomine, programmi, organigrammi e monitoraggio degli adempimenti."
                }
              />
              <Card
                img={"./images/icons/aule.png"}
                title={"AULE FORMAZIONE"}
                text={
                  "Crea e gestisci con semplicità le aule per la formazione con registrazione dei corsisti e monitoraggio dei progressi dello stato della formazione."
                }
              />
              <Card
                img={"./images/icons/attestati.png"}
                title={"ATTESTATI PIÙ RAPIDI"}
                text={
                  "Ottieni gli attestati di partecipazione in modo semplice. Gestisci con semplicità la pre-fatturazione dei corsi di formazione."
                }
              />
              <Card
                img={"./images/icons/monitoraggio.png"}
                title={"MONITORAGGIO SEMPLIFICATO"}
                text={
                  "Monitora la compliance dei diversi adempimenti aziendali, grazie a report e dashboard intuitive e semplici."
                }
              />
              <Card
                img={"./images/icons/dvr.png"}
                title={"GESTIONE DVR"}
                text={
                  "Diminuisci i tempi dedicati alla creazione dei DVR (Documento di Valutazione dei Rischi) grazie al sistema di generazione guidata."
                }
              />
              <Card
                img={"./images/icons/pianificazione.png"}
                title={"PIANIFICAZIONE E ORGANIZZAZIONE"}
                text={
                  "Pianifica precisamente le visite mediche tramite un comodo scadenzario. Migliora la produttività generando in maniera automatica protocolli sanitari e nomine."
                }
              />
              <Card
                img={"./images/icons/soprallughi.png"}
                title={"GESTIONE SOPRALLUOGHI"}
                text={
                  "Organizza i sopralluoghi nelle sedi operative e crea verbali di sicurezza dettagliati. Gestisci facilmente la pre-fatturazione delle visite e dei sopralluoghi."
                }
              />
            </div>
            <div className="w-full mt-8 lg:hidden block">
              <div className="w-full m-auto md:px-20 p-0 flex justify-between items-center gap-x-5 relative group">
                <div className="w-full">{slides[currentSlideIndex]}</div>
                <div className=" arrows -left-2 ">
                  <MdOutlineKeyboardArrowLeft
                    onClick={handlePrevSlide}
                    size={30}
                    className="hover:scale-110"
                  />
                </div>
                <div className=" arrows -right-2 ">
                  <MdOutlineKeyboardArrowRight
                    onClick={handleNextSlide}
                    size={30}
                    className="hover:scale-110"
                  />
                </div>
              </div>
              <div className="flex top-4 justify-center py-2">
                {slides.map((slide, slideIndex) => (
                  <div
                    key={slideIndex}
                    onClick={() => handleDotClick(slideIndex)}
                    className="text-2xl cursor-pointer hover:scale-125"
                  >
                    <RxDotFilled
                      className={`duration-700 transition-all text-[28px] ${
                        currentSlideIndex === slideIndex ? "scale-150" : ""
                      }`}
                      color={
                        currentSlideIndex === slideIndex
                          ? "#0a6497"
                          : "rgb(0 0 0 / 0.2)"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </RevealOnScroll>
        </section>
        <section className="flex flex-wrap items-center px-5 md:px-10 w-full py-10">
          <RevealOnScroll>
            <div className="w-full">
              <div className=" flex justify-center md:justify-start  mb-5">
                <span className="uppercase md:text-8px text-sm font-bold text-lightMiller border border-lightMiller rounded-md lg:px-8 px-6 py-[7px] leading-tight text-center">
                  L’offerta
                </span>
              </div>

              <h2 className="text-title text-white mb-4">
                La nostra piattaforma per la gestione dei dati HSE
              </h2>
              <h3 className="text-subtitle text-white mb-8">
                Zero vincoli! Scegli l'offerta più adatta alla tua azienda.
              </h3>
            </div>

            <div className="w-full flex flex-wrap items-center justify-center">
              <div className="w-full grid md:flex justify-around items-center flex-wrap md:flex-nowrap gap-x-2 xl:gap-x-8 lg:mx-32 xl:mx-40 space-y-4 md:space-y-0">
                <div
                  onClick={() => {
                    handleOfferte(0);
                    handleAncor();
                  }}
                  className={`py-12 sm:py-12 md:py-6 lg:py-6 xl:py-6 px-6 bg-white z-30 rounded-lg  md:mx-0 mx-auto w-[250px] sm:w-[280px] cursor-pointer  hover:-translate-y-4 transition-all duration-300 ring-2 ring-gray-900/5 ${
                    price === 0
                      ? "shadow-xl shadow-lightMiller/70 ring-2 ring-lightMiller"
                      : "ring ring-gray-900/5"
                  }`}
                >
                  <div className="pt-2">
                    <h1 className="text-[#1f5fd3] text-4xl font-bold">
                      €99
                      <span className="text-lg text-darkMiller font-normal">
                        /mese
                      </span>
                    </h1>
                  </div>
                  <h1 className="text-darkMiller font-bold text-2xl">
                    Starter
                  </h1>
                  <div className=" mt-3 md:h-[180px]">
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      1 users
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      1 modulo
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      Fino 50 anagrafiche
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]"></div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]"></div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]"></div>
                  </div>
                  <div className="mt-4 ">
                    <p className="leading-none text-sm opacity-75">
                      <i>
                        *Il costo non include l'onboarding Starter, obbligatorio
                        e una tantum, al costo di 300 €.
                      </i>
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => {
                    handleOfferte(1);
                    handleAncor();
                  }}
                  className={`py-12 sm:py-12 md:py-6 lg:py-6 xl:py-6 px-6 bg-[#1f5fd3] z-30 rounded-lg  md:mx-0 mx-auto w-[250px] sm:w-[280px] cursor-pointer   hover:-translate-y-4 transition-all duration-300 ring-2 ring-gray-900/5 ${
                    price === 1
                      ? "shadow-xl shadow-white/70 ring-2 ring-white"
                      : "ring ring-gray-900/5"
                  }`}
                >
                  <div className="pt-2">
                    <h1 className="text-white text-4xl font-bold">
                      €179
                      <span className="text-lg text-white font-normal">
                        /mese
                      </span>
                    </h1>
                  </div>
                  <h1 className="text-white font-bold text-2xl">
                    Professional
                  </h1>
                  <div className=" mt-3 md:h-[200px]">
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      3 users
                    </div>
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      Accesso a tutti i moduli
                    </div>
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      Live chat
                    </div>
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      Gestione team
                    </div>
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      <p>Fino 100 anagrafiche</p>
                    </div>
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      Reportistica
                    </div>
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      Notarizzazione documentale
                    </div>
                    <div className="text-sm text-white flex flex-nowrap items-baseline gap-2  leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="bg-white rounded-full text-emerald-600 w-[14px] h-[14px] self-center" />
                      AI chat (fino 100 query mese)
                    </div>
                  </div>
                  <div className="mt-4 ">
                    <p className="leading-none text-sm opacity-75 text-white">
                      <i>
                        *Il costo non include l'onboarding Professional,
                        obbligatorio e una tantum, al costo di 500 €.
                      </i>
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => {
                    handleOfferte(2);
                    handleAncor();
                  }}
                  className={`py-12 sm:py-12 md:py-6 lg:py-6 xl:py-6 px-6 bg-white z-30 rounded-lg  md:mx-0 mx-auto w-[250px] sm:w-[280px] cursor-pointer   hover:-translate-y-4 transition-all duration-300  ${
                    price === 2
                      ? "shadow-xl shadow-lightMiller/70 ring-2 ring-lightMiller"
                      : "ring ring-gray-900/5"
                  }`}
                >
                  <div className="pt-2">
                    <h1 className="text-[#1f5fd3] text-4xl font-bold">
                      €249
                      <span className="text-lg text-darkMiller font-normal">
                        /mese
                      </span>
                    </h1>
                  </div>
                  <h1 className="text-darkMiller font-bold text-2xl">
                    Enterprise
                  </h1>
                  <div className=" mt-3 md:h-[180px]">
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      5 users
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      Accesso a tutti i moduli
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      Live chat notarizzata
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      Gestione team e permessi
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      <p>Fino 250 anagrafiche</p>
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      Reportistica custom
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      Notarizzazione documentale
                    </div>
                    <div className="text-sm text-darkMiller flex flex-nowrap items-baseline gap-2 leading-none py-[3px] lg:py-[5px]">
                      <BsFillCheckCircleFill className="self-center text-emerald-600 w-[14px] h-[14px]" />
                      AI chat (fino 1.000 query mese)
                    </div>
                  </div>
                  <div className="mt-4 ">
                    <p className="leading-none text-sm opacity-75">
                      <i>
                        *Il costo non include l'onboarding Enterprise,
                        obbligatorio e una tantum, al costo di 2.500 €.
                      </i>
                    </p>
                  </div>
                </div>
              </div>
              <p className="mt-10 text-white text-sm text-justify">
                <i>
                  Per la versione Starter il costo del modulo aggiuntivo è di 25
                  €/m mentre per tutte le versioni il costo di ogni utenza
                  aggiuntiva è di 30 €/m. Nella versione Professional e
                  Enterprise il costo per ogni ulteriore anagrafica è di 0,35
                  €/m (0,06 €/m nel caso di professionisti o società di
                  consulenza). Sempre nella versione Enterprise il numero di
                  ambienti massimo - per professionisti e aziende di consulenza
                  - è di 30 (ogni ulteriore ambiente ha il costo di 40 €/a)
                </i>
              </p>
            </div>
          </RevealOnScroll>
        </section>
        <section className="h-3 w-full bg-white"></section>
        <section className="bg-[#0A6497]">
          <RevealOnScroll>
            <div className="grid grid-cols-2 gap-6 px-5 lg:px-10 w-full py-10 ">
              <div className="col-span-2 lg:col-span-1 order-1 lg:order-1 space-y-4">
                <h2 className=" text-white text-title">Koala VR</h2>
                <h3 className=" text-white text-subtitle text-justify">
                  L'esperienza immersiva della realtà virtuale per simulare
                  scenari realistici di rischio in un ambiente di lavoro.
                </h3>
                <h3 className=" text-white text-subtitle text-justify ">
                  I corsi all'interno della nostra piattaforma:
                </h3>
                <br />
                <div className=" w-full flex items-center gap-x-3 max-w-xl mx-auto">
                  <div className="text-white md:w-20 md:h-20 w-16 h-16 row-span-3 self-center bg-estintore bg-cover bg-center"></div>
                  <h6 className="text-base md:text-xl text-white  text-left ">
                    ANTINCENDIO
                  </h6>
                </div>
                <div className=" w-full flex items-center gap-x-3  max-w-xl mx-auto">
                  <div className="text-white md:w-20 md:h-20 w-16 h-16 row-span-3 self-center bg-scatola bg-cover bg-center"></div>
                  <h6 className="text-base md:text-xl text-white  text-left ">
                    MOVIMENTAZIONE MANUALE CARICHI
                  </h6>
                </div>
                <div className=" w-full flex items-center gap-x-3  max-w-xl mx-auto">
                  <div className="text-white md:w-20 md:h-20 w-16 h-16 row-span-3 self-center bg-carretto bg-cover bg-center"></div>
                  <h6 className="text-base md:text-xl text-white  text-left ">
                    TRANS-PALLET E CARRETTI ELEVATORI
                  </h6>
                </div>
                <div className=" w-full flex items-center gap-x-3  max-w-xl mx-auto">
                  <div className="text-white md:w-20 md:h-20 w-16 h-16 row-span-3 self-center bg-attenzione bg-cover bg-center"></div>
                  <h6 className="text-base md:text-xl text-white  text-left ">
                    RISCHI UFFICI E MAGAZZINO
                  </h6>
                </div>
              </div>
              <div className="col-span-2 order-2 lg:order-3 lg:col-span-1 flex justify-center items-center">
                <img
                  src={require("./images/Koala VR.png")}
                  alt=""
                  className="lg:h-[600px] md:h-[500px] h-full"
                />
              </div>
            </div>
          </RevealOnScroll>
        </section>
        <section className="flex flex-col w-full px-5 md:px-10 py-10 bg-cover bg-fixed bg-center items-center bg-bg2560">
          <RevealOnScroll>
            <h2 className=" text-darkMiller text-title">
              Le caratteristiche chiave di Koala VR
            </h2>
            <div className="grid grid-cols-2 gap-10 p-10">
              <div className="col-span-2 lg:col-span-1 flex flex-col items-start bg-white rounded-md px-6 py-4  shadow-xl shadow-darkMiller/30 ring-1 ring-gray-900/5  ">
                <h1 className="text-lg font-bold text-darkMiller">
                  SICUREZZA SUL LAVORO INTERATTIVA
                </h1>
                <h4>
                  Rivoluziona l'approccio alla sicurezza con moduli interattivi
                  che coinvolgono attivamente il personale, accrescendo la
                  consapevolezza e la prevenzione
                </h4>
              </div>
              <div className="col-span-2 lg:col-span-1 flex flex-col items-start bg-white rounded-md px-6 py-4  shadow-xl shadow-darkMiller/30 ring-1 ring-gray-900/5 ">
                <h1 className="text-lg font-bold text-darkMiller">
                  ESPERIENZA IMMERSIVA REALISTICA
                </h1>
                <h4>
                  Immergiti in scenari di lavoro virtuali progettati per
                  simulare situazioni reali, potenziando la comprensione e la
                  reattività alle norme di sicurezza
                </h4>
              </div>
              <div className="col-span-2 lg:col-span-1 flex flex-col items-start bg-white rounded-md px-6 py-4  shadow-xl shadow-darkMiller/30 ring-1 ring-gray-900/5 ">
                <h1 className="text-lg font-bold text-darkMiller">
                  APPRENDIMENTO PRATICO E DINAMICO
                </h1>
                <h4>
                  Favorisci un apprendimento efficace attraverso pratiche
                  dinamiche e interattive che cementano la conoscenza e le
                  competenze in ambito HSE
                </h4>
              </div>
              <div className="col-span-2 lg:col-span-1 flex flex-col items-start bg-white rounded-md px-6 py-4  shadow-xl shadow-darkMiller/30 ring-1 ring-gray-900/5 ">
                <h1 className="text-lg font-bold text-darkMiller">
                  PERFORMANCE MISURABILI
                </h1>
                <h4>
                  Trasforma i dati di sicurezza in azioni concreti con strumenti
                  analitici avanzati per misurare, valutare e migliorare le
                  performance di sicurezza
                </h4>
              </div>
              <div className="col-span-2 flex justify-center items-center">
                <button
                  className="py-2.5 px-6 text-sm md:text-lg font-semibold tracking-wide text-white hover:text-darkMiller hover:bg-white bg-lightMiller hover:border-darkMiller border hover:scale-105 transition-all ease-in-out duration-300 rounded-full cursor-pointer uppercase"
                  onClick={() => {
                    handleOfferte(3);
                    handleAncor();
                  }}
                >
                  Richiedi un preventivo per Koala VR
                </button>
              </div>
            </div>
          </RevealOnScroll>
        </section>
        <section className="flex flex-col w-full px-5 md:px-10 py-10 bg-cover bg-fixed bg-center items-center bg-bg2560">
          <RevealOnScroll>
            <div className="w-full">
              <div className=" flex justify-center md:justify-start  mb-5">
                <span className="uppercase md:text-8px text-sm font-bold text-lightMiller border border-lightMiller rounded-md lg:px-8 px-6 py-[7px] leading-tight text-center">
                  CONTATTACI
                </span>
              </div>
            </div>
            <div className="w-full relative flex flex-wrap md:flex-nowrap">
              <div className=" lg:w-2/5 w-full md:mr-5 lg:mr-0">
                <h2 className="text-title text-darkMiller">
                  Scopri cosa può fare KOALA per te
                </h2>
                <h2 className="text-darkMiller text-subtitle text-left font-medium leading-tight tracking-tight md:mt-7 mt-2 md:mb-0 mb-2">
                  La soluzione all in one di Miller Group per la gestione HSE
                  della tua azienda.
                </h2>
                <div className="hidden md:flex flex-wrap">
                  <button className=" w-full relative py-2 px-4 mt-5 text-center text-sm md:text-lg  tracking-wide  text-darkMiller bg-white  border border-darkMiller transition-all ease-in-out duration-300 rounded-lg cursor-pointer hover:-translate-y-1">
                    <HiOutlineMail className="text-xl absolute left-5 top-[50%] -translate-y-[50%]" />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:koala@millergroup.it"
                    >
                      koala@millergroup.it
                    </a>
                  </button>
                  <button className=" w-full relative py-2 px-4 mt-5 text-center text-sm md:text-lg  tracking-wide  text-darkMiller bg-white  border border-darkMiller transition-all ease-in-out duration-300 rounded-lg cursor-pointer hover:-translate-y-1">
                    <HiPhone className="text-xl absolute left-5 top-[50%] -translate-y-[50%]" />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="tel:0236762490"
                    >
                      0236762490
                    </a>
                  </button>
                </div>
                <div className="hidden md:flex flex-nowrap justify-between w-4/5 sm:w-2/3 mx-auto mt-5">
                  <a
                    rel="noopener noreferrer"
                    href="https://wa.me/message/FRPB5ZTNJJGRF1"
                    title="Whatsapp"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsWhatsapp size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/miller-&-partners-srl"
                    title="Linkedin"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsLinkedin size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/MillerGroupItaly/?ref=br_rs"
                    title="Facebook"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsFacebook size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/millergroup_/"
                    title="Instagram"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsInstagram size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://open.spotify.com/show/0n1oF4q0xxcK6emAFB2uSv"
                    title="Spotify"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsSpotify size={30} className=" text-darkMiller" />
                  </a>
                </div>
              </div>
              <div className=" lg:w-3/5 w-full lg:pl-24 pl-0 lg:mt-0 mt-8 ">
                <div className="form lg:min-w-min w-full h-full">
                  <div className="flex-input flex md:flex-row flex-col items-center md:space-x-4 space-x-0 md:space-y-0 space-y-4 mb-4">
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.nome}
                      name="nome"
                      type="text"
                      placeholder="Nome"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    />
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.cognome}
                      name="cognome"
                      type="text"
                      placeholder="Cognome"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    />
                  </div>
                  <div className="flex-input flex md:flex-row flex-col items-center md:space-x-4 space-x-0 md:space-y-0 space-y-4 mb-4">
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.azienda}
                      name="azienda"
                      type="text"
                      placeholder="Nome azienda"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    />
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.telefono}
                      name="telefono"
                      type="text"
                      placeholder="Telefono"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    />
                  </div>
                  <div className="flex-input flex md:flex-row flex-col items-center md:space-x-4 space-x-0 md:space-y-0 space-y-4 mb-4">
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.email}
                      name="email"
                      type="text"
                      placeholder="E-mail"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    />
                    <select
                      onChange={(e) => handleOfferte(Number(e.target.value))}
                      value={price}
                      name="email"
                      type="text"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    >
                      {offerte.map((el) => {
                        return (
                          <option key={el.id} value={el.id}>
                            {el.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex-input flex md:flex-row flex-col items-center md:space-x-4 space-x-0 md:space-y-0 space-y-4 mb-4">
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.accessi}
                      name="accessi"
                      type="number"
                      placeholder="Numero accessi"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    />
                    <select
                      onChange={(e) => handleTipologie(Number(e.target.value))}
                      value={tipo}
                      name="tipologia"
                      type="text"
                      className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                    >
                      {tipologia.map((el) => {
                        return (
                          <option key={el.id} value={el.id}>
                            {el.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <textarea
                    onChange={(e) => handleInputs(e)}
                    value={user.messagio}
                    name="messagio"
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Messaggio"
                    className="border-2 border-darkMiller rounded-lg p-2 pl-4 w-full focus:outline-lightMiller"
                  ></textarea>
                  <div>
                    <ReCAPTCHA
                      sitekey="6LeBbWIoAAAAAOVs7qOkd66zyMqKIZu8ClJc6vxW"
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    ref={buttonMail}
                    onClick={() => sendEmail()}
                    className="w-full md:w-1/3 py-2.5 px-6 mt-5 text-center text-sm md:text-lg font-semibold tracking-wide text-white hover:text-darkMiller hover:bg-white bg-lightMiller hover:border-2 hover:border-darkMiller transition-all ease-in-out duration-300 rounded-full cursor-pointer"
                  >
                    INVIA
                  </button>
                  <br />
                </div>
              </div>
            </div>
            <div className="md:hidden w-full flex flex-wrap md:flex-nowrap mt-10">
              <div className="flex flex-wrap md:flex-nowrap w-full md:w-1/2 space-y-2 md:space-y-0 md:space-x-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:koala@millergroup.it"
                  className=" w-full relative py-2 px-4  text-center text-sm md:text-lg  tracking-wide  text-darkMiller bg-white  border border-darkMiller transition-all ease-in-out duration-300 rounded-lg cursor-pointer hover:-translate-y-1"
                >
                  <HiOutlineMail className="text-xl absolute left-5 top-[50%] -translate-y-[50%]" />
                  koala@millergroup.it
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="tel:0236762490"
                  className=" w-full relative py-2 px-4  text-center text-sm md:text-lg  tracking-wide  text-darkMiller bg-white  border border-darkMiller transition-all ease-in-out duration-300 rounded-lg cursor-pointer hover:-translate-y-1"
                >
                  <HiPhone className="text-xl absolute left-5 top-[50%] -translate-y-[50%]" />
                  0236762490
                </a>
              </div>
              <div className="flex flex-nowrap w-full md:w-1/2 mt-2 md:mt-0 ">
                <div className="flex flex-nowrap justify-between w-4/5 sm:w-1/2 mx-auto ">
                  <a
                    rel="noopener noreferrer"
                    href="https://wa.me/message/FRPB5ZTNJJGRF1"
                    title="Whatsapp"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsWhatsapp size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/miller-&-partners-srl"
                    title="Linkedin"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsLinkedin size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/MillerGroupItaly/?ref=br_rs"
                    title="Facebook"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsFacebook size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/millergroup_/"
                    title="Instagram"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsInstagram size={30} className=" text-darkMiller" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://open.spotify.com/show/0n1oF4q0xxcK6emAFB2uSv"
                    title="Spotify"
                    className="flex items-center p-1 hover:-translate-y-1 transition-all ease-in-out duration-300"
                    target="_blank"
                  >
                    <BsSpotify size={30} className=" text-darkMiller" />
                  </a>
                </div>
              </div>
            </div>
          </RevealOnScroll>
        </section>
        <footer>
          <div className="flex flex-wrap  w-full justify-between py-5 md:py-10 px-5 text-white">
            <div className="w-full md:w-3/5 text-left ">
              <div className="self-center text-2xl font-semibold w-full md:w-48 h-16 bg-contain bg-no-repeat bg-center bg-logo-white mx-auto md:mx-0 mb-2"></div>
              <h3 className="text-[16px] lg:text-[21px] md:text-left text-center mt-2 leading-tight md:px-0 ">
                Koala è la nuova piattaforma di Miller Group che integra
                sicurezza sul lavoro, formazione, sorveglianza sanitaria e
                wellbeig.
              </h3>
            </div>
            <div className=" w-full md:w-2/5">
              <div className="w-full">
                <a target="_blank" href="https://millergroup.it/">
                  <div className="self-center text-2xl font-semibold w-48 h-16 bg-contain bg-no-repeat bg-center bg-logo-miller md:ml-auto md:mx-0 mx-auto"></div>
                </a>
              </div>
            </div>
            <div className="w-full text-white grid grid-cols-3 md:flex items-center justify-between mt-10 flex-wrap ">
              <a className="col-span-1 md:w-fit py-3 text-center">
                P.IVA 07204280965
              </a>
              <div className="col-span-1 h-[80%] md:h-full  mx-auto w-[2px] bg-lightMiller"></div>
              <a
                className="col-span-1 md:w-fit py-3 cursor-pointer text-center"
                rel="noopener noreferrer"
                target="_blank"
                href="https://millergroup.it/it/informativa-privacy/"
              >
                Informativa Privacy
              </a>
              <div className="md:block hidden md:h-full w-[2px] mx-auto bg-lightMiller"></div>
              <a
                className="col-span-1 md:w-fit py-3 cursor-pointer text-center"
                rel="noopener noreferrer"
                target="_blank"
                href="https://millergroup.it/it/cookie-policy/"
              >
                Cookie Policy
              </a>
              <div className="col-span-1 h-[80%] md:h-full mx-auto w-[2px] bg-lightMiller"></div>
              <a
                className="col-span-1 md:w-fit py-3 cursor-pointer text-center"
                rel="noopener noreferrer"
                target="_blank"
                href="https://millergroup.it/wp-content/uploads/2024/04/Company_profile_MillerGroup_2024.pdf"
              >
                Company Profile
              </a>
              <div className="md:block hidden md:h-full w-[2px] mx-auto bg-lightMiller"></div>
              <a className="col-span-3 md:w-fit py-3 text-center">
                Property of Miller Group SRL
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

const RevealOnScroll = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const scrollObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        scrollObserver.unobserve(entry.target);
      }
    });

    scrollObserver.observe(ref.current);

    return () => {
      if (ref.current) {
        scrollObserver.unobserve(ref.current);
      }
    };
  }, []);

  const classes = `w-full transition-all ease-in-out duration-1500 
      ${isVisible ? "scale-100" : "scale-75"}`;

  return (
    <div ref={ref} className={classes}>
      {children}
    </div>
  );
};
